.label {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: black;
}
.hTable {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.icon {
  font-size: 2rem;
  color: #c4c4c4;
}
.icon:hover {
  cursor: pointer;
}

.activeSphere,
.inActiveSphere,
.sphere {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  padding: 0.25rem;
}

.activeSphere {
  background: linear-gradient(
    55deg,
    rgba(63, 64, 63, 0.75) 0%,
    rgba(39, 94, 35, 0.75) 33.33%,
    rgba(17, 160, 5, 0.75) 66.66%,
    rgba(151, 253, 142, 0.75) 100%
  );
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.inActiveSphere {
  background: linear-gradient(
    55deg,
    rgb(0 0 0 / 75%) 0%,
    rgb(144 28 28 / 89%) 33.33%,
    rgb(239 5 5 / 75%) 66.66%,
    rgba(253, 142, 142, 0.75) 100%
  );
}
