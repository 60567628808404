.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  /* font-family: swiper-icons;*/

  color: rgb(76 175 80);
  font-size: 20px;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: -5px;
  right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: -5px;
  left: auto;
}
