.label {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  /* font-size: 18px; */
  font-size: 14px;
  color: black;
}

.labelCheckbox {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
}
.borderFull,
.borderFull input {
  border-width: 0.2rem !important;
}
.borderBottom,
.borderBottom input {
  border-width: 0px 0px 5px !important;
}
.borderBottom,
.borderBottom input,
.borderFull,
.borderFull input {
  border-color: #4caf50 !important;
  border-radius: 0px !important;
  /* padding: 4px !important; */
  width: 100%;
}
.borderBottom input[autocomplete="off"] {
  border-width: 0px 0px 3px !important;
}

.passwordHint p,
.passwordHint li,
.passwordHint span {
  font-family: Roboto;
  font-style: normal;
  font-size: 12px;
}

h6 {
  margin: 0.25rem 0;
}
