.sum {
  background: linear-gradient(97.01deg, #056f09 20.18%, #000000 155.25%),
    #c4c4c4;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 61px;
  display: flex;
}

.count {
  background: linear-gradient(97.62deg, #6bd56f 20.11%, #4caf50 95.15%), #c4c4c4;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 61px;
  display: flex;
}

.icon {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 78px;
  color: #ffffff;
  padding-left: 4px;
  margin-right: 10px;
}

.number {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  display: block;
  align-self: flex-end;
  margin-bottom: 10px;
  text-align: end;
  width: 100%;
  margin-right: 10px;
}

.iconTwo {
  font-size: 12px;
  padding-left: 5px;
}

.ratingOperators {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.25);
  display: flex;
  width: 50%;
  padding: 0px 8px;
  cursor: pointer;
}
.comingRatActive {
  color: rgba(0, 0, 0);
}
.expendRatActive {
  color: rgba(0, 0, 0);
}
.ratingFioOperator {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  display: inline-block;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.73);
  margin-top: 0px;
}
.ratingAmountOperator {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.73);
}

.topOrgTspan {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.topOrgTspanAmount {
  font-family: Roboto;
  font-style: normal;
  font-size: 18px;
  line-height: 16px;
  color: #000000;
}

@media screen and (min-width: 768px) {
  .number {
    font-size: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .number {
    font-size: 24px;
  }
}
