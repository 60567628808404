.emptyData {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.25);
  display: block;
  padding: 16px;
}

.dFlex {
  display: flex;
}
.dFlexColumn {
  display: flex;
  flex-direction: column;
}
.active {
  color: #000 !important;
}
.main_title {
  font-size: x-large;
  width: 100%;
  padding: 0.25rem 0.25rem;
  margin: 0;
}
.icon {
  font-size: 2rem;
  color: #c4c4c4;
  height: 2.25rem !important;
  width: 2.25rem !important;
  padding: 0.25rem;
  border-radius: 50%;
  margin: 0 0.25rem;
}
.icon:hover {
  cursor: pointer;
  background-color: #efefefbd;
  border: 1px solid #c4c4c4;
}

.sorts span,
.sortComponent {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.25);
}

.sorts > span {
  cursor: default;
  margin-right: 10px;
  margin-bottom: 5px;
}
.sorts > span,
.sortComponent {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}
.sortComponent {
  cursor: pointer;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
}
.arrowLeft {
  transform: rotate(90deg);
  margin-right: -8px;
}
.arrowRight {
  transform: rotate(-90deg);
  margin-left: -8px;
}
.arrowLeft,
.arrowRight {
  height: 1rem !important;
}
.arrowLeftActive,
.arrowRightActive,
.sortActive span {
  color: black;
}

.fastDateFilter {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.25);
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.datePickerLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-right: 10px;
}
.datePicker {
  height: 1px;
}
.datePicker input {
  padding: 0;
}

.rangeComponent__label,
.multiComponent__label {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  align-self: center;
  display: flex;
  justify-content: flex-start;
  width: 25%;
  margin-right: 0.5rem;
}

.rangeComponent,
.multiComponent {
  width: 75%;
  /* margin: 0rem 1rem; */
  display: flex;
}

.rangeComponent span {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  width: 100%;
}
.rangeComponent__input {
  display: flex;
  align-items: center;
  width: 50%;
}
.rangeComponent__input label {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-right: 10px;
}
.rangeComponent__input input,
.multiComponent__selects {
  border: 1px solid #000000 !important;
  box-sizing: border-box !important;
  border-radius: 0px !important;
  width: 50%;
  font-size: 12px;
  line-height: 16px;
  /* text-align: center; */
  color: rgba(0, 0, 0, 0.5);
}
.multiComponent__selects {
  width: 100% !important;
}

.signature {
  font-family: Roboto;
  font-size: 10px;
}

.orderACall {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  padding-top: 6px;
  cursor: pointer;
}
.successIcon {
  position: absolute;
  left: calc(50% - 50px);
  bottom: calc(50% - 50px);
}
.textResult h3 {
  font-family: RobotoBold;
  line-height: 48px;
  text-align: center;
  font-size: 34px;
  letter-spacing: -0.0944444px;
  color: #3a3335;
  margin-top: 10px;
  margin-bottom: 10px;
}
.textResult span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #908e8e;
}

.filtersIcon {
  color: #c4c4c4;
  cursor: pointer;
  display: flex;
}
.filtersIconActive {
  color: #4caf50 !important;
}

.filtersIcon span {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* color: #C4C4C4; */
  margin-right: 0.5rem;
}

.filterComponent {
  overflow: hidden;
  -webkit-transition: opacity 0.1s, height 0.5s 0.4s ease-out;
  transition: opacity 0.1s, height 0.5s 0.4s ease-out;
  height: 0px;
  opacity: 1;
}

.filterComponentActive {
  -webkit-transition: height 0.5s ease-out, opacity 0.1s 0.5s;
  transition: height 0.5s ease-out, opacity 0.1s 0.5s;
  height: 100%;
  opacity: 1;
}

.overflow {
  overflow: visible;
}

@media screen and (min-width: 768px) {
  .rangeComponent__label,
  .multiComponent__label {
    font-size: 14px;
  }
}
