.auth_block {
  height: 100%;
}
.auth_block_bg {
  height: 100%;
  width: 100%;
  /* background-color: rgb(255 255 255);
  box-shadow: 0px 0px 30px -6px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  padding: 10px 5%;
  text-align: center;
}
.title_logotype img {
  height: 86px;
}
.title_logotype span {
  font-family: MontserratBlack;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 15px;
  color: black;
  margin-left: 0.75rem;
}
.content {
  /* background-color: black; */
  width: 100%;
  height: 100%;
}
.sidebar {
  position: sticky;
  top: calc(50% - 150px);
}
.greeting {
  font-size: 2rem;
}
.register {
  min-height: 377px;
}
.entity:hover,
.register:hover {
  /* color: hsl(251, 100%, 50%); */
  /* text-decoration: underline; */
}
.currentUserSpan {
  line-height: 21px;
}
.RegistrationView span {
  font-size: 18px;
}
.RegistrationView h1 {
  font-size: 48px;
  margin: 1rem 0;
}
.RegistrationView p {
  font-size: 12px !important;
  margin: 0px 0px 1.5rem 0;
  padding-right: 20px;
}
.AuthView {
}
.forgotPasswordText {
  font-weight: 300;
  font-size: 12px !important;
  line-height: 20px;
  margin: 0px;
  color: black;
}
.forgotPasswordText a {
  color: black;
}

.titleReg,
.titleAuth,
.titlePass {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 21px;
  color: #4caf50;
}
.bodyReg,
.bodyAuth {
  min-height: calc(100vh - 300px);
}
.footerReg,
.footerAuth {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
}
.footerReg > a,
.footerAuth > a {
  color: #4caf50 !important;
}

.messagePassword {
  font-size: 12px !important;
  padding-left: 0.5rem;
  margin: -7px 0 0px;
}
.cardAuth {
  max-width: 334px;
}
.layout {
  padding: 0px 14px;
  background-image: url(./img/sphera.png);
  background-repeat: no-repeat;
  background-position: 150% -33px;
  background-size: 75%;
}
.layout p,
span {
  font-family: Roboto;
  font-size: 14px;
}
.header {
  height: 71px;
  padding: 15px 15px 15px 4px !important;
}
.header_notBorderLeft {
  border-left: 0px !important;
}
.register {
}
.titleBlock {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  padding-left: 1.5rem;
}

.ForWhat .ellipse {
  background-image: url(./img/Ellipse.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
}

.ForWhat p {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}
.ForWhat span {
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
}

.WhyUsSpan {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  padding-left: 60px;
  padding-top: 0.25rem;
  display: block;
}
.HowWeSolveProblems {
}

.WhoAreWeTryingFor {
}

.WhoAreWeTryingFor .picture {
  height: 254px;
  position: relative;
  margin-left: 20px;
}
.WhoAreWeTryingFor .picture img:first-child {
  width: 295px;
  bottom: 0px;
  left: 0px;
  z-index: -1;
  position: absolute;
}
.WhoAreWeTryingFor .picture img:last-child {
  position: absolute;

  bottom: 133px;
  left: 243px;
  width: 90px;
}

.HowCashRegistersWork {
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
}
.HowCashRegistersWork > div:first-child {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
}
.HowCashRegistersWork > p:last-child {
  width: 100%;
  padding: 0rem 0.5rem 0.5rem 1.5rem;
  margin: 0px;
}

.cmsLinks {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #656a6d;
  margin-right: 8px;
  margin-left: 8px;
}

.buttonSuccess {
  background: linear-gradient(
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgb(76, 175, 80);
  box-shadow: rgb(0 0 0 / 15%) 4px 4px 15px;
  height: 45px;
  max-width: 250px;
  width: calc(100% - 16px);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  border-radius: 5px !important;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media screen and (min-width: 576px) {
  .layout {
    background-position: 135% -30px;
    background-size: 65%;
  }
}

@media screen and (min-width: 768px) {
  .layout {
    background-position: 114% 50px;
    background-size: 50%;
  }
  .header {
    background-color: white;
    padding: 15px 15px 15px 39px !important;
    border-bottom: 1px solid rgba(76, 175, 80, 0.25);
    border-left: 1px solid rgba(76, 175, 80, 0.25);
  }
  .titleReg,
  .titleAuth {
    padding-left: 8rem;
  }
  .titlePass {
    padding-left: 2rem !important;
  }
  .register {
    border-left: 1px solid rgba(76, 175, 80, 0.25);
  }
  .image_md_none {
    background-image: none !important;
  }
  .HowCashRegistersWork > div:first-child {
    height: 74px;
    background-color: #a2e7a5;
  }
  .HowCashRegistersWork > p:last-child {
  }
}

@media screen and (min-width: 992px) {
  .layout {
    background-position: 100% 50px;
    background-size: 44%;
  }
  .WhoAreWeTryingFor .picture img:first-child {
    width: 390px;
  }
  .WhoAreWeTryingFor .picture img:last-child {
    bottom: 180px;
    left: 310px;
    width: 120px;
  }
  .header {
    padding: 15px 15px 15px 39px !important;
  }
}

@media screen and (min-width: 1200px) {
  .layout {
    background-position: 95% 60px;
    background-size: 550px;
  }
  .WhoAreWeTryingFor .picture {
    height: 325px;
  }
  .WhoAreWeTryingFor .picture img:first-child {
    width: 470px;
  }
  .WhoAreWeTryingFor .picture img:last-child {
    bottom: 220px;
    left: 389px;
    width: 140px;
  }
  .header {
    padding: 15px 15px 15px 15px !important;
  }
}

@media screen and (min-width: 1440px) {
  .WhoAreWeTryingFor .picture img:first-child {
    width: 550px;
  }
  .WhoAreWeTryingFor .picture img:last-child {
    bottom: 257px;
    left: 456px;
    width: 165px;
  }
}
