.title {
  font-size: x-large;
}
.blocktitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;

  color: rgba(0, 0, 0, 0.5);
}
.dialog {
  /* position: absolute;
  top: 67px;
  left: 50px;
  max-height: calc(100vh - 70px) !important; */
  /* width: calc(100% - 50px); */
}
.font {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: black;
}
.form {
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
}
.borderNone,
.borderNone input {
  border-width: 0px 0px 0px !important;
  text-align: end;
  padding: 0.5rem 0;
}
.borderBottom,
.borderBottom input {
  border-width: 0px 0px 5px !important;
  border-color: #4caf50 !important;
  border-radius: 0px !important;
}
.buttons button {
  height: 71px !important;
  border: 0.2rem solid !important;
  /* max-width: 200px; */
  width: 100% !important;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #4caf50 !important;
}
.buttons button > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}
.amount input {
  color: #056f09;
  opacity: 1 !important;
  font-size: x-large;
  font-weight: bold;
}
.inputOpacity1,
.inputOpacity1 input {
  opacity: 1 !important;
}
.checkboxBox > div {
  border-radius: 0px !important;
  border: 2px solid black !important;
}
.btn {
  background: #0a6e09 !important;
  border-radius: 0px !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 14px !important;
  margin-left: 1rem !important;
  margin-top: -4px !important;
  height: 34px !important;
}

.btn span:first-child {
  padding-right: 0.75rem;
  border-right: 1px solid;
}
.scrollpanel {
  max-height: 435px;
}

.acquiring {
  display: flex;
  padding: 0.5rem;
}
.acquiring label {
  font-family: RobotoBold !important;
  font-size: 18px !important;
  margin-right: 20px;
  color: #4caf50;
  display: block;
}
.shadow {
  box-shadow: 4px 4px 15px rgb(0 0 0 / 15%);
}

@media screen and (min-width: 576px) {
  .scrollpanel {
    max-height: 300px;
  }
}
@media screen and (min-width: 768px) {
  .scrollpanel {
    max-height: 265px;
  }
}
