.column {
  font-family: Roboto;
  font-style: normal;
  display: flex;
  flex-direction: column;
}

.column span:first-child {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding-bottom: 0.25rem;
}

.column span:last-child {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.emptyData {
  text-align: center;
}

.Numerics {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Numerics span {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
}

@media screen and (min-width: 768px) {
  .Numerics span {
    font-size: 24px;
  }
}
