body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}
.p-button {
  border-radius: 0px;
}

.text-size-18 {
  font-size: 18px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.body-main {
  width: 100%;
}

.p-toast-top-right {
  z-index: 99999 !important;
}

.c-window-organization {
}

.errorText {
  color: rgb(214 2 2);
  margin: 0;
  font-size: 12px !important;
}

.c-col-min-6 {
  min-width: 50vw;
}

.title-page {
  font-size: x-large;
}
.button-menu-table {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border: none !important;
  color: #8e8b8b !important;
}
.button-menu-table:focus {
  box-shadow: 0 0 0 0.2rem #bbbbbb !important;
}
.c-btns span {
  font-size: 14px !important;
}

.c-btns--gradient {
  background: linear-gradient(272.13deg, #000000 -66.34%, #056f09 89.81%),
    #056f09 !important;
  width: 100%;
  height: 45px;
}
.с-button-danger {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #ec1b1bce !important;
  width: 100%;
  height: 49px;
  border: 1px solid !important;
}
.с-button-success {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #4caf50 !important;
  width: 100%;
  height: 49px;
}
.c-btn-outline {
  width: 40px;
  border: none;
  height: 40px;
  border-radius: 50%;
  background: none;
}
.c-btn-outline:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
.c-btn-outline.c-btn-danger:hover {
  background: rgba(255, 156, 156, 0.281);
}
.c-btn-outline.c-btn-success:hover {
  background: rgba(156, 255, 186, 0.281);
}
.с-button-success.p-button .p-button-label {
  /* font-weight: bold; */
  font-size: 14px;
  line-height: 16px;
}
.p-button.c-btns--gradient > span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.15rem 1rem !important;
}
.off-shadow {
  box-shadow: none !important;
}
.window-multiselect
  .p-multiselect-panel.p-component.p-connected-overlay-enter-done
  .p-multiselect-items-wrapper {
  max-height: 128px !important;
}
.dialog-header.p-dialog .p-dialog-header .p-dialog-title {
  color: rgba(0, 0, 0, 0.5);
  padding-left: 1.5rem;
}
.block-label-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 1);
}

.block-label-title.title-bold {
  color: #000000;
}
.block-label-title.title-silver {
  color: rgba(0, 0, 0, 0.5) !important;
}
.label-description {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
}

.c-confirm-dialog > .p-dialog-footer > .p-confirm-dialog-accept {
  background-color: white;
  color: #2b702f;
  border: 1px solid #2b702f;
  box-sizing: border-box;
}
.c-confirm-dialog > .p-dialog-footer > .p-confirm-dialog-reject {
  box-sizing: border-box;
  /*border: 1px solid #4CAF50;*/
}
.c-confirm-dialog > .p-dialog-footer > .p-confirm-dialog-reject:hover {
  border: 1px solid #4caf50 !important;
}
.c-confirm-dialog > .p-dialog-footer > .p-confirm-dialog-accept:hover {
  border: 1px solid #4caf50 !important;
  background: rgba(76, 175, 80, 0.04);
  color: #4caf50;
}

.c-input {
  border-width: 0px 0px 5px !important;
  border-color: #4caf50 !important;
  border-radius: 0px !important;
}
.c-input-opacity-1,
.c-input-opacity-1 input {
  opacity: 1 !important;
}
.c-label-font {
  font-weight: bold;
  font-size: 18px;
  color: black;
}
.c-calendar {
  /* width: 180px;
  height: 185px; 
  border-radius: 0px !important;
  min-width: 40% !important;
  top: -80px !important;
  left: calc(50% - 112px) !important;*/
}
.p-datepicker.c-calendar table td > span {
  font-size: 12px;
  width: 1.5rem;
  height: 1.5rem;
}
.p-datepicker.c-calendar table tr > span,
.p-datepicker.c-calendar table th > span {
  font-size: 10px;
}
.p-datepicker.c-calendar table td,
.p-datepicker.c-calendar table tr,
.p-datepicker.c-calendar table th {
  padding: 0.25rem;
}
.p-datepicker.c-calendar .p-datepicker-header {
  background: #4caf50 !important;
}
.p-datepicker.c-calendar {
  padding: 0px;
  border: none;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
}
.p-datepicker.c-calendar
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-month,
.p-datepicker.c-calendar
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-year {
  color: white;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}
.p-datepicker.c-calendar .p-datepicker-header .p-datepicker-prev,
.p-datepicker.c-calendar .p-datepicker-header .p-datepicker-next {
  color: #fff;
}
.p-dialog.c-dialog .p-dialog-header .p-dialog-title {
  color: #000000;
}
.c-scrollPanel.p-scrollpanel .p-scrollpanel-bar {
  background: #056f09;
  border: 0 none;
  border-radius: 0px;
  opacity: 1;
}
.p-scrollpanel.p-component.c-scrollPanel.scrollDataView {
  box-shadow: inset -12px 0px 0px -3px #aedeb9c4 !important;
}

.block-hide {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in;
}
.block-show {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.c-header-center .p-dialog-header > span {
  padding: 0.25rem;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding-left: 32px;
}
.p-dialog-mask.p-component-overlay,
.p-sidebar-mask.p-component-overlay {
  background-color: rgba(0, 50, 2, 0.25) !important;
  /* backdrop-filter: blur(0.05rem) brightness(100%) ; */
}

.analytics-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}

.analytics-table-cashboxes div {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  overflow-wrap: anywhere;
}

.analytics-column-org {
  font-weight: 100 !important;
}

.p-paginator .p-paginator-current,
.p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 1.7rem !important;
  height: 1.7rem !important;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-prev {
  min-width: 1.357rem !important;
  height: 1.357rem !important;
}

.p-paginator {
  padding: 0.5rem 0.5rem !important;
}
