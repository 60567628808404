.column {
  font-family: Roboto;
  font-style: normal;
}

.column span:first-child {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding-bottom: 0.25rem;
}

.column span:last-child {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.status span {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}
.resultSum {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}
.resultSumBorder {
  border-bottom: 2px solid #4caf50;
}

.closeWinReceipt {
  width: 100%;
  height: calc(100vh - 0px);
  max-height: 100vh !important;
  background-color: white;
  box-shadow: none;
  margin: 0px !important;
}

@media screen and (min-width: 768px) {
  .closeWinReceipt {
    height: calc(100vh - 72px);
  }
}
