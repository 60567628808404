.closeWin {
  width: 100%;
  height: calc(100vh - 0px);
  max-height: 100vh !important;
  background-color: white;
  box-shadow: none;
  margin: 0px !important;
}

@media screen and (min-width: 768px) {
  .closeWin {
    height: calc(100vh - 72px);
  }
}
