.list span {
  padding-right: 0.5rem;
  font-size: 12px;
}

.list li {
  list-style-type: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  padding: 0.5rem 0;
}
.list {
  padding: 0px;
  margin: 0px;
}
.list li a {
  color: #000;
}
.list li:hover,
.list li:hover a {
  color: #4caf50;
}
