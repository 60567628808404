.balance {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #000000;
  display: flex;
  min-height: 100px;
  justify-content: center;
  align-items: center;
}
.blockInvoice {
  display: flex;
  min-height: 100px;
  justify-content: center;
  align-items: center;
}
