.header {
  height: 70px;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 900;
}
.logo {
}
.logo a {
  font-family: MontserratBlack;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 15px;
  color: black;
}
.icon > span {
  display: none;
}
.icon {
  padding: 0px 10px !important;
}
.iconPrime {
  color: rgba(0, 0, 0, 0.5) !important;
}

.currentUser {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  line-height: 1px;
  color: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 576px) {
  .header {
    height: 70px;
  }
  .logo a {
    font-size: 14px;
  }
  .icon {
    padding: 0px 15px !important;
  }
  .currentUser {
    font-size: 12px;
  }
  .iconPrime > span {
    font-size: 1.2rem !important;
  }
}

@media screen and (min-width: 768px) {
  .logo a {
    font-size: 18px;
    line-height: 22px;
  }
  .icon {
    padding: 0px 15px !important;
  }
  .header {
    height: 70px;
  }
  .currentUser {
    font-size: 14px;
  }
  /* .iconPrime > span{
    font-size: 1.4rem !important;
  } */
}
