.hLabel {
  font-size: 14px !important;
  align-self: center;
}
.local {
  /* width: 250px; */
}

.colorLabel label {
  color: #4caf50 !important;
}
.printOnPaper > div > div {
  border-color: #4caf50 !important;
}

.printOnPaper label {
  font-family: RobotoBold !important;
  font-size: 18px !important;
  margin-right: 20px;
  color: #000;
  display: block;
  /* width: 70%; */
}
.printOnPaper > div.p-checkbox {
  align-self: flex-end !important;
}
.bidWindow {
  width: 100%;
  height: calc(100vh - 0px);
  max-height: 100vh !important;
  background-color: white;
  box-shadow: none;
  margin: 0px !important;
}

@media screen and (min-width: 768px) {
  .bidWindow {
    height: calc(100vh - 72px);
  }
}
