@media screen and (min-width: 576px) {
  .body-main {
    margin: 0px 15px;
  }
  .c-mdi-icon--header {
    font-size: 1.6rem !important;
  }
}
@media screen and (min-width: 768px) {
  .c-scrollPanel-mobile-show.p-scrollpanel .p-scrollpanel-bar {
    opacity: 1;
  }
  .body-main {
    margin: 0px 8px;
  }
  .c-mdi-icon--header {
    font-size: 1.7rem !important;
  }
}
@media screen and (min-width: 992px) {
  .c-scrollPanel-mobile-show.p-scrollpanel .p-scrollpanel-bar {
    opacity: 1;
  }
  .body-main {
    margin: 0px 10px;
  }
  .p-button.c-btns--gradient > span {
    font-size: 14px;
    /* font-weight: bold; */
  }
}
@media screen and (min-width: 1200px) {
  .body-main {
    margin: 0px 12px;
  }
}
@media screen and (max-width: 768px) {
  .c-scrollPanel-mobile-show.p-scrollpanel .p-scrollpanel-bar {
    opacity: 1;
  }
  .p-inputtext {
    font-size: 14px !important;
  }
}
