.circle2 {
  fill: #fb7a7abf;
}
.x {
  opacity: 1;
}
.xSingle {
  fill: #fff;
}

.svgIconError {
  width: 70px;
  height: 70px;
  display: inline-block;
  position: absolute;
  left: calc(50% - 35px);
  bottom: calc(50% - 35px);
}

.svgIconError.isAnimated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.svgIconError.isAnimated .xSingle {
  animation-name: rotate;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  transform-origin: 50% 50%;
}

@keyframes dasharray {
  0% {
    stroke-dasharray: 0 100;
  }

  100% {
    stroke-dasharray: 100 0;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
    opacity: 0;
  }

  100% {
    transform: rotate(315deg);
    opacity: 1;
  }
}
