.button {
  background: linear-gradient(
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgb(76, 175, 80);
  box-shadow: rgb(0 0 0 / 15%) 4px 4px 15px;
  height: 45px;
  max-width: 250px;
  width: calc(100% - 16px);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  border-radius: 5px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.auth_block {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1.25rem;
  background-color: white;
  box-shadow: 0px 0px 30px -6px;
  max-width: 250px;
  border-radius: 8px;
}

.logo_vdcloud {
  width: 52px;
  padding: 0.25rem;
}
.logo_name {
  color: #8a8a8a;
  font-family: "RobotoBold";
  font-size: 18px;
  letter-spacing: -0.1px;
  text-shadow: 1px 1px 0px #6b6b6b;
}
