.icon {
  width: 27px;
  height: 27px;
  transition: 0.5s;
  transform: rotate(0deg);
}
.icon svg {
  border: 1px solid rgba(0, 0, 0, 0.295);
  border-radius: 50%;
  padding: 4px 4px 4px 6px;
  margin: 0.1rem;
}
.iconActive {
  transform: rotate(90deg);
  transition: 0.5s;
}

.line {
  height: 0px;
  margin: 0 auto;
  width: 0%;
  background-color: #4caf50;
  -webkit-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
}
.item.itemActive .line {
  height: 1px;
  width: 98%;
  margin: 0.25rem auto;
  -webkit-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
}

.item {
  overflow: hidden;
  -webkit-transition: 1s;
  transition: 1s;
  min-height: 0px;
  max-height: 0px;
}
.item.itemActive {
  -webkit-transition: 1s;
  transition: 1s;
  min-height: 62px;
  max-height: 1200px;
}
