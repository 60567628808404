.window {
  width: 100%;
  height: calc(100vh - 0px);
  max-height: 100vh !important;
  background-color: white;
  box-shadow: none;
  margin: 0px !important;
  /* top: 68px; */
}

.delegate {
  display: flex;
}
.delegate > div > div {
  border-color: #4caf50 !important;
}

.delegate label {
  font-family: RobotoBold !important;
  font-size: 18px !important;
  margin-right: 20px;
  color: #4caf50;
  display: block;
  /* width: 70%; */
}
.delegate > div.p-checkbox {
  align-self: flex-end !important;
}

@media screen and (min-width: 768px) {
  .window {
    height: calc(100vh - 72px);
    /* top: 34px; */
  }
}
