.sidebar {
  width: 62px;
  left: -62px;
  transition: all 0.5s ease-out;
  /* background: linear-gradient(174.66deg, #4caf50 3%, #000000 98.76%), #ffffff; */
  background: linear-gradient(208deg, #4caf50 4%, #000000 84.76%), #ffffff;
  box-shadow: 3px 6px 14px 0px rgb(0 0 0 / 0%);
  position: fixed;
  height: 100%;
  z-index: 900;
}

.sidebarActive {
  width: 230px;
  left: 0;
  box-shadow: 3px 6px 14px 0px rgb(0 0 0 / 41%);
  transition: all 0.5s ease-out;
}

.sidebarLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 12px;
  padding-left: 44px;
}

.item,
.itemMenu {
  padding: 0rem 0.5rem;
}
.item svg {
  margin: 0.5rem 8px;
}
.item a,
.itemMenu div {
  color: #ffffff;
  display: flex;
  align-items: center;
}
.item span,
.itemMenu span {
  overflow: hidden;
  margin: 0 8px;
  margin-left: 14px;
  font-size: 16px;
  line-height: 21px;
  white-space: nowrap;
}

.item:hover {
  box-sizing: border-box;
}

.itemActive {
  border: 1px solid #fff;
  margin: 0px 2px;
  background: linear-gradient(
    135.73deg,
    #ffffff -37%,
    rgba(255, 255, 255, 0) 47.39%
  );
  box-sizing: border-box;
}

@media screen and (min-width: 768px) {
  .sidebar {
    left: 0px;
  }
  .hide {
    min-width: 62px;
    transition: all 0.5s ease-out;
  }
  .hideActive {
    min-width: 215px;
    max-width: 350px;
    transition: all 0.55s ease-out;
  }
}
