.block {
  display: flex;
  /* width: 100%; */
  padding: 0.25rem;
}

.text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding-top: 5px;
}
