.pagination span,
.pagination button {
  font-size: 12px !important;
}
.pagination button {
  min-width: 1.75rem !important;
  height: 1.75rem !important;
}

@media screen and (min-width: 576px) {
  .style_pagination__2lp3I button {
    min-width: 2rem !important;
    height: 2rem !important;
  }
}
