/* W3C standard
   сейчас только для Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #cacacab3 #056f09;
}

/* для Chrome/Edge/Safari */
*::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
*::-webkit-scrollbar-track {
  background: #cacacab3;
}
*::-webkit-scrollbar-thumb {
  background-color: #056f09;
  border-radius: 0px;
}

.custom-spinner-connect {
  width: 100%;
  height: 100%;
  top: 0% !important;
  left: 0% !important;
  z-index: 99;
}

.icon-style-1 span {
  font-size: 1.75rem !important;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
  left: calc(var(--overlayArrowLeft, 0) + 0.75rem) !important;
  /* content: none !important; */
}

.p-dialog.notification .p-dialog-header {
  padding: 0.5rem 1rem;
  font-family: Roboto;
}

.p-toast {
  max-width: 340px;
}

.text-small {
  font-size: 12px;
}

.c-h-400 {
  height: 400px;
}
.c-h-300 {
  height: 300px;
}
.c-h-250 {
  height: 250px;
}

.p-unselectable-text {
  width: 100%;
}

.c-pB-mobile-100 {
  padding-bottom: 100px !important;
}
.c-body-full {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.p-flex-grow-1 {
  flex-grow: 1 !important;
}

.empty-list {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100px;
}

.label-color-green label {
  color: #4caf50;
}

.line-height-14 {
  line-height: 14px;
}
.p-button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.c-position-relative {
  position: relative !important;
}
.c-position-absolute {
  position: absolute !important;
}
.c-top-5 {
  top: 5px !important;
}
.c-top-10 {
  top: 10px !important;
}
.c-left-5 {
  left: 5px !important;
}
.c-left-10 {
  left: 10px !important;
}
.c-right-5 {
  right: 5px !important;
}
.c-right-10 {
  right: 10px !important;
}
.c-bottom-5 {
  bottom: 5px !important;
}
.c-bottom-10 {
  bottom: 10px !important;
}

/* .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {

  -webkit-box-shadow: none !important;
  box-shadow: none !important;
} */

.p-progressbar {
  height: 0.5rem !important;
  border-radius: 0px !important;
}

.p-multiselect-close {
  display: none !important;
}

/* Card - primereact component */
.p-card {
  background: #ffffff;
  color: #495057;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15) !important;
  border-radius: 0px !important;
}
.p-card .p-card-body {
  padding: 1rem !important;
}
.p-card .p-card-content {
  padding: 0 !important;
}
/* Checkbox - primereact component */
.p-checkbox .p-checkbox-box {
  border: 2px solid #000000 !important;
  border-radius: 0px;
  background: #ffffff;
  color: #495057;
}
.p-button {
  border-radius: 0px !important;
}

.c-filter-blur {
  filter: blur(1px);
}
.c-absolute-center {
  position: absolute;
  left: calc(50% - 50px);
  bottom: calc(50% - 50px);
}
.c-absolute-top {
  position: absolute;
  left: calc(50% - 50px);
  top: 50px;
}

/*
.p-dialog-mask.p-component-overlay {
  z-index: 10001 !important;
}
*/

.c-header-center.p-dialog .p-dialog-header {
  padding: 1.25rem !important;
  border-bottom: 2px solid #eceef5;
  box-sizing: border-box;
}
.c-header-center.p-dialog .p-dialog-content {
  padding: 1.25rem !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.p-password-panel.p-component.p-connected-overlay-enter-done {
  max-height: 330px;
  padding: 0.75rem !important;
}
.p-dropdown-item,
.p-multiselect-item {
  white-space: inherit !important;
  font-size: 14px;
}
.c-hover-cursor:hover {
  cursor: pointer;
}

.customer-logotype {
  align-self: center;
  display: flex;
}
.customer-logotype img {
  padding: 0.25rem;
  width: 40px;
  height: 40px;
}
.customer-logotype span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.25rem;
}
.customer-logotype span {
  font-family: RobotoBold;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 15px;
  color: black;
}

.c-progressSpiner {
  /* width: 50px;
  height: 50px; */
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  z-index: 999;
}

.c-box-shadow {
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
}

.c-darkgreen-btn.p-button.p-component,
.c-green-btn.p-button.p-component {
  height: 45px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  border-radius: 0px;
}
.c-darkgreen-btn.p-button.p-component {
  background: linear-gradient(272.13deg, #000000 -66.34%, #056f09 89.81%),
    #056f09;
}
.c-green-btn.p-button.p-component {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #4caf50;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
}
.c-darkgreen-btn.p-button:enabled:active,
.c-darkgreen-btn.p-button:enabled:hover {
  background: linear-gradient(272.13deg, #000000 -66.34%, #056f09 89.81%),
    #056f09;
  border-width: #056f09;
}
.c-darkgreen-btn.p-button:focus {
  box-shadow: none;
}

.c-border-bottom,
.c-border-bottom input {
  border-width: 0px 0px 5px !important;
  border-color: #4caf50 !important;
  border-radius: 0px !important;
  opacity: 1 !important;
}
.c-progressSpiner {
  top: calc(50% - 50px);
  left: calc(50% - 50px);
}
/* .inputOpacity1,
.inputOpacity1 input {
  
} */

.c-window-page.p-dialog {
  /*top: 68px !important;*/
}
.c-window-page.p-dialog .p-dialog-header {
  box-shadow: inset 0px 7px 10px -2px rgb(0, 0, 0, 15%) !important;
}

.c-color-green {
  color: #4caf50;
}
.c-color-darkgreen {
  color: #056f09 !important;
}

.c-button {
  border-radius: 0px !important;
}
.c-button--icon .p-button-icon-left {
  margin-right: 1rem !important;
  font-size: 1.5rem;
}

.p-tabview-panels {
  padding: 1rem 0 0px 0rem !important;
}

.c-card-bb {
  border-bottom: 0.05rem solid rgba(0, 0, 0, 0.295);
}

.c-theader-shadow {
  box-shadow: 0px 6px 6px -5px rgb(0 0 0 / 15%);
}

.c-disabled {
  pointer-events: none;
  opacity: 0.4;
}

.c-chip.p-chip {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.35));
  background-color: white;
  font-family: RobotoBold;
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  border: 1px solid #0000000f;
  line-height: 21px;
  color: #4caf50;
}
.c-chip-active.p-chip {
  color: #fff;
  background-color: #4caf50;
}
.c-shadow-2 {
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
  border: 1px solid #d0d0d063;
}

.p-dialog-content {
  padding: 0 0.5rem 2rem 0.5rem !important;
}
.c-pagination-table {
  min-height: calc(100vh - 300px);
}
.c-window-page.window-sidebar-inActive {
  /* padding-left: 0px; */
  width: 100%;
  transition: width 0.5s ease-out;
}
.c-window-page.window-sidebar-active {
  /* padding-left: 0px; */
  width: 100%;
  transition: width 0.5s ease-out;
}
.c-window-page.p-dialog-content {
  height: 100% !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.label-printOnPaper label {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

@media screen and (min-width: 768px) {
  .p-unselectable-text {
    width: auto;
  }
  .c-pB-mobile-100 {
    padding-bottom: 10px !important;
  }
  .customer-logotype span {
    font-size: 18px;
    line-height: 22px;
  }
  .c-window-page.p-dialog {
    top: 34px !important;
  }
  .p-dialog-content {
    padding: 0 1.5rem 2rem 1.5rem !important;
  }
  .c-window-page.window-sidebar-inActive {
    /* padding-left: 62px; */
    width: calc(100% - 62px);
  }
  .c-window-page.window-sidebar-active {
    /* padding-left: 230px; */
    width: calc(100% - 230px);
  }
}

.receipt-filters-show {
  height: calc(100vh - 423px);
  transition: 0.3s ease-out;
}
.receipt-filters-hide {
  height: calc(100vh - 310px);
  transition: 0.3s ease-out;
}
.p-dialog {
  max-height: 100% !important;
}

.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.795);
  backdrop-filter: blur(1px);
}
.dashboard-label {
  font-family: Roboto;
  font-size: 12px;
  line-height: 10px;
  font-weight: bold;
}
.dashboard-label-other {
  font-family: Roboto;
  font-size: 14px;
  line-height: 10px;
}

.large-list-row--cashbox {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.p-datepicker table th,
.p-datepicker table td {
  padding: 0.25rem !important;
}

.p-datepicker table td > span {
  width: 2rem !important;
  height: 2rem !important;
  font-size: 12px !important;
}

.p-datepicker .p-datepicker-buttonbar {
  padding: 0.5rem 0 !important;
}

.p-datepicker-year {
  border-color: #fff;
  cursor: pointer;
  border-bottom: 1px solid;
}
